import React from 'react'
import { Link } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'

import { rhythm } from '../utils/typography'
import Logo from './Logo'
import ThemeSwitch from './ThemeSwitch'
import { useTheme } from '../utils/context'
import { prismjsStyles } from '../utils/prismjs'
import { LIGHT_THEME, DARK_THEME } from '../utils/theme'

const GlobalStyles = createGlobalStyle`
  ${prismjsStyles}
  body {
    --inlineCode-bg: rgba(255, 229, 100, 0.2);
    --inlineCode-text: #1a1a1a;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

    background-color: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.backgroundColor
        : LIGHT_THEME.backgroundColor}
  }

  ::selection {
    background: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.selectionColor
        : LIGHT_THEME.selectionColor};

    color: ${LIGHT_THEME.textColor};
  }

  a,
  u {
    text-decoration: none;
  }

  li {
    color: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.textColor
        : LIGHT_THEME.textColor};
  }

  a {
    color: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.postTitleColor
        : LIGHT_THEME.postTitleColor};
  }

  h1,
  h2,
  h3 {
    color: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.postTitleColor
        : LIGHT_THEME.postTitleColor};
  }

  p {
    text-align: justify;
    color: ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.textColor
        : LIGHT_THEME.textColor};
  }

  blockquote {
    margin-left: -1.75rem;
    margin-right: 1.75rem;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 1.42188rem;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    line-height: 1.75rem;
    color: inherit;
    font-style: italic;
    border-left: 0.4rem solid ${props =>
      props.theme && props.theme.color === 'dark'
        ? DARK_THEME.dividerColor
        : LIGHT_THEME.quoteColor};
    opacity: 0.8;
  }

  .gatsby-resp-image-wrapper {
    max-width: 100% !important;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${props => (props.fullWidth ? `100%` : rhythm(30))};
  padding: ${props => (props.fullWidth ? 0 : `0 ${rhythm(3 / 4)}`)};
  padding-bottom: ${rhythm(3 / 4)};

  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: ${rhythm(30)};
    padding: ${props => (props.fullWidth ? `0 ${rhythm(3 / 4)}` : 0)};

    ul {
      list-style: none;
      display: flex;
      padding: 10px 8px 10px 0;
      margin: 0;
      li {
        a {
          font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
          font-size: 1.25em;
          box-shadow: none;
          color: ${props =>
            props.theme && props.theme.color === 'dark'
              ? DARK_THEME.postTitleColor
              : LIGHT_THEME.postTitleColor};
        }
        margin: 0;
      }
    }

    @media only screen and (max-width: 672px) {
      ul {
        justify-content: center;
      }
    }
  }
`

function Layout({ location, title, children, fullWidth = false }) {
  const { theme, toggleTheme } = useTheme()

  const renderHeader = () => (
    // eslint-disable-next-line no-undef
    // const rootPath = `${__PATH_PREFIX__}/`

    // if (location && location.pathname === rootPath) {
    //   return (
    //     <h1
    //       style={{
    //         ...scale(1.5),
    //         marginBottom: rhythm(1.5),
    //         marginTop: 0,
    //       }}
    //     >
    //       <Link
    //         style={{
    //           fontFamily: `Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace`,
    //           boxShadow: `none`,
    //           textDecoration: `none`,
    //           color: `var(--textNormal)`,
    //           fontSize: rhythm(1.5),
    //         }}
    //         to="/"
    //       >
    //         <Logo text={title} />
    //       </Link>
    //     </h1>
    //   )
    // }

    <div className="header">
      <ul>
        <li>
          <Link to="/">
            <Logo text={title} />
          </Link>
        </li>
        {/* <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li> */}
      </ul>
      <ThemeSwitch theme={theme} onChange={toggleTheme} />
    </div>
  )

  return (
    <>
      <GlobalStyles />
      <Wrapper fullWidth={fullWidth}>
        {renderHeader()}
        {children}
      </Wrapper>
    </>
  )
}

export default Layout
