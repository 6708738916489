export const LIGHT_THEME = {
  backgroundColor: '#ffffff',
  headerTextColor: '#686868',
  textColor: '#292929',
  postTitleColor: '#3f3f3f',
  postDateColor: '#b9b9b9',
  selectionColor: '#f0c420',
  tagColor: '#b9b9b9',
  quoteColor: '#b9b9b9',
  dividerColor: '#292929',
  inlineCodeColor: '#1a1a1a',
  backgroundCodeColor: '#F4F2F0',
}

export const DARK_THEME = {
  backgroundColor: '#1F2937',
  headerTextColor: '#686868',
  textColor: '#d1d5db',
  postTitleColor: '#f1f2f4',
  postDateColor: '#4B5663',
  selectionColor: '#f0c420',
  tagColor: '#4B5663',
  quoteColor: '#f1f2f4',
  dividerColor: '#f0c420',
  inlineCodeColor: '#cecece',
  backgroundCodeColor: '#111827',
}

export const transitionDuration = {
  slow: '0.2s',
  normal: '0.125s',
  fast: '0.0.1s',
}
