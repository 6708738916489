import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Moon from './icons/Moon'
import Sun from './icons/Sun'

export const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  outline: none;
  display: inline-block;
  overflow: hidden;
`

const ThemeSwitch = ({ theme, onChange }) => (
  <ThemeProvider theme={{ color: theme }}>
    <Wrapper
      className="hvr-icon-spin"
      onClick={onChange}
      role="button"
      tabIndex={0}
      onKeyPress={e => {
        if (e.which === 13 || e.which === 32) {
          onChange(e)
        }
      }}
    >
      {theme === 'dark' ? (
        <Moon className="hvr-icon" />
      ) : (
        <Sun className="hvr-icon" />
      )}
    </Wrapper>
  </ThemeProvider>
)

export default ThemeSwitch
